<template>
  <div
    class="d-flex justify-center align-center"
    style="overflow: scroll; height: 73vh;"
  >
    <div class="d-flex align-center justify-center flex-wrap">
      <div
        class="mt-3"
        style="width: 18vw;"
        v-for="(area, ind) of resultadosAreas"
        :key="ind"
      >
        <div
          class="d-flex flex-row justify-center align-center pa-1"
          style="border: 1px solid #BDBDBD; width: 325px; background-color: #1976D2; color:white; border-radius: 5px;"
        >
          <span>{{ area.area }}</span>
        </div>
        <div
          class="d-flex flex-row justify-space-between pa-1"
          style="border: 1px solid #BDBDBD; width: 325px; background-color: #1976D2; color:white;"
        >
          <span>Mês</span>
          <span>Meta</span>
          <span>%</span>
        </div>
        <div v-for="(item, index) in area.resultTrimestre" :key="index">
          <div
            class="d-flex flex-row justify-space-between align-center pa-1"
            v-for="(itens, i) in metas.filter(m => m.trimestre === index + 1)"
            :key="i"
            style="border: 1px solid #BDBDBD; width: 325px;"
          >
            <span class="caption" style="width: 70px;">{{ itens.mes }}</span>
            <span
              v-if="item.valorCurr > 0"
              class="caption"
              style="display: flex; justify-content: end;align-items: end;width: 100px;"
              >{{
                Number((item.valorCurr / 3).toFixed(2)).toLocaleString(
                  "pt-BR",
                  { currency: "BRl", style: "currency" },
                )
              }}</span
            >
            <span
              v-else
              class="caption"
              style="display: flex; justify-content: end;align-items: end;width: 100px;"
              >-</span
            >
            <span
              class="caption"
              style="display: flex; justify-content: end;align-items: end;width: 100px;"
              >-</span
            >
          </div>
          <div
            v-if="item.valorCurr > 0"
            class="d-flex flex-row justify-space-between pa-1"
            style="border: 1px solid #BDBDBD; width: 325px; background-color: #BBDEFB;"
          >
            <span>{{ item.title }}</span>
            <span>{{ item.valor }}</span>
            <span v-if="item.percentTrimestre"
              >{{ item.percentTrimestre }}%</span
            >
            <span v-else>-</span>
          </div>
          <div
            v-else
            class="d-flex flex-row justify-space-between pa-1"
            style="border: 1px solid #BDBDBD; width: 325px; background-color: #BBDEFB;"
          >
            <span>{{ item.title }}</span>
            <span>-</span>
            <span>-</span>
          </div>
        </div>
        <div
          class="d-flex flex-row justify-space-between pa-1 mt-1"
          style="border: 1px solid #BDBDBD; width: 325px; background-color: hsl(59, 79%, 46%); border-radius: 5px;"
        >
          <span>Total Geral</span>
          <span>{{
            Number(area.totalArea.toFixed(2)).toLocaleString("pt-BR", {
              currency: "BRL",
              style: "currency",
            })
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { stringToBrMoney } from "@/utils/stringParse";

import { add, sub } from "date-fns";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "calcGerencia",
  computed: {
    ...mapState({
      tabFaturamento: state => state.metasOpimed.tabFaturamento,
      headersFaturamento: state => state.metasOpimed.headersFaturamento,
      indiceReajuste: state => state.metasOpimed.indiceReajuste,
      ajustes: state => state.metasOpimed.ajustes,
      tabMetas: state => state.metasOpimed.tabMetas,
      trimestre: state => state.metasOpimed.trimestre,
      dadosCalculo: state => state.metasOpimed.dadosCalculo,
      anoEscolhido: state => state.metasOpimed.anoEscolhido,
    }),
  },
  data: () => {
    return {
      name: "Cálculo gerência",
      resultadosAreas: [],
      resultTrimestre: [
        { title: "1 Trimestre", valor: 0, percent: 0, trimestre: 1 },
        { title: "2 Trimestre", valor: 0, percent: 0, trimestre: 2 },
        { title: "3 Trimestre", valor: 0, percent: 0, trimestre: 3 },
        { title: "4 Trimestre", valor: 0, percent: 0, trimestre: 4 },
      ],
      metas: [
        { mes: "Janeiro", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Fevereiro", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Março", meta: 0, percent: 0, trimestre: 1 },
        { mes: "Abril", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Maio", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Junho", meta: 0, percent: 0, trimestre: 2 },
        { mes: "Julho", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Agosto", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Setembro", meta: 0, percent: 0, trimestre: 3 },
        { mes: "Outubro", meta: 0, percent: 0, trimestre: 4 },
        { mes: "Novembro", meta: 0, percent: 0, trimestre: 4 },
        { mes: "Dezembro", meta: 0, percent: 0, trimestre: 4 },
      ],
    };
  },
  methods: {
    async buscaIndice() {
      const dadosCalc = this.dadosCalculo[0].itens[0];

      this.resultadosAreas = [];

      if (dadosCalc.ZXD_TIPO === "PP") {
        dadosCalc.ZXD_TIPO = "LJ";
      }
      //Consultar a tabela de espelho de calculo

      this.indiceBanco = [];
      this.travaCampos = false;
      const url = `${process.env.VUE_APP_BASE_API_URL}/node/consultas`;
      let dados = {};

      dados = {
        FIELDS: "ZXE_ANOTRI,ZXE_AREA,ZXE_META",
        WHERE: `ZXE_AREA LIKE 'A%' AND LEFT(ZXE_ANOTRI,4)='${this.anoEscolhido}' `,
        TABELA: "ZXE",
      };

      const areasMetas = await axios.post(url, dados);

      const areas = [...new Set(areasMetas.data.map(e => e.ZXE_AREA.trim()))];

      for (const area of areas) {
        this.metas = [
          { mes: "Janeiro", meta: 0, percent: 0, trimestre: 1 },
          { mes: "Fevereiro", meta: 0, percent: 0, trimestre: 1 },
          { mes: "Março", meta: 0, percent: 0, trimestre: 1 },
          { mes: "Abril", meta: 0, percent: 0, trimestre: 2 },
          { mes: "Maio", meta: 0, percent: 0, trimestre: 2 },
          { mes: "Junho", meta: 0, percent: 0, trimestre: 2 },
          { mes: "Julho", meta: 0, percent: 0, trimestre: 3 },
          { mes: "Agosto", meta: 0, percent: 0, trimestre: 3 },
          { mes: "Setembro", meta: 0, percent: 0, trimestre: 3 },
          { mes: "Outubro", meta: 0, percent: 0, trimestre: 4 },
          { mes: "Novembro", meta: 0, percent: 0, trimestre: 4 },
          { mes: "Dezembro", meta: 0, percent: 0, trimestre: 4 },
        ];
        this.resultTrimestre = [
          { title: "1 Trimestre", valor: 0, percent: 0, trimestre: 1 },
          { title: "2 Trimestre", valor: 0, percent: 0, trimestre: 2 },
          { title: "3 Trimestre", valor: 0, percent: 0, trimestre: 3 },
          { title: "4 Trimestre", valor: 0, percent: 0, trimestre: 4 },
        ];
        dados = {
          FIELDS: "SUM(ZXE_META) ZXE_META,ZXE_ANOTRI,ZXE_AREA",
          WHERE: `ZXE_AREA='${area}' AND LEFT(ZXE_ANOTRI,4)='${this.anoEscolhido}' GROUP BY ZXE_ANOTRI,ZXE_AREA `,
          TABELA: "ZXE",
        };

        const metasBanco = await axios.post(url, dados);

        if (metasBanco.data.length > 0) {
          metasBanco.data.map(e => {
            if (Number(e.ZXE_ANOTRI.substr(4, 2)) === 1) {
              this.metas
                .filter(m => m.trimestre === 1)
                .map(m => {
                  m.meta = stringToBrMoney(e.ZXE_META);
                  m.metaCurr = Number(e.ZXE_META.toFixed(2));
                });
              this.resultTrimestre
                .filter(t => t.trimestre === 1)
                .map(t => {
                  t.valor = stringToBrMoney(e.ZXE_META * 3);
                  t.valorCurr = Number((e.ZXE_META * 3).toFixed(2));
                });
            }
            if (Number(e.ZXE_ANOTRI.substr(4, 2)) === 2) {
              this.metas
                .filter(m => m.trimestre === 2)
                .map(m => {
                  m.meta = stringToBrMoney(e.ZXE_META);
                  m.metaCurr = Number(e.ZXE_META.toFixed(2));
                });
              this.resultTrimestre
                .filter(t => t.trimestre === 2)
                .map(t => {
                  t.valor = stringToBrMoney(e.ZXE_META * 3);
                  t.valorCurr = Number((e.ZXE_META * 3).toFixed(2));
                });
            }
            if (Number(e.ZXE_ANOTRI.substr(4, 2)) === 3) {
              this.metas
                .filter(m => m.trimestre === 3)
                .map(m => {
                  m.meta = stringToBrMoney(e.ZXE_META);
                  m.metaCurr = Number(e.ZXE_META.toFixed(2));
                });
              this.resultTrimestre
                .filter(t => t.trimestre === 3)
                .map(t => {
                  t.valor = stringToBrMoney(e.ZXE_META * 3);
                  t.valorCurr = Number((e.ZXE_META * 3).toFixed(2));
                });
            }
            if (Number(e.ZXE_ANOTRI.substr(4, 2)) === 4) {
              this.metas
                .filter(m => m.trimestre === 4)
                .map(m => {
                  m.meta = stringToBrMoney(e.ZXE_META);
                  m.metaCurr = Number(e.ZXE_META.toFixed(2));
                });
              this.resultTrimestre
                .filter(t => t.trimestre === 4)
                .map(t => {
                  t.valor = stringToBrMoney(e.ZXE_META * 3);
                  t.valorCurr = Number((e.ZXE_META * 3).toFixed(2));
                });
            }
          });
        }

        const anoAnterior = sub(new Date(this.anoEscolhido, 0, 1), {
          years: 1,
        });

        let inicioAnoAnterior = new Date(anoAnterior.getFullYear(), 0, 1);

        let trimestreCont = 1;
        let contMes = 0;
        let valorTrimAnterior = 0;

        for (let cont = 0; cont <= 12; cont++) {
          const mes = add(inicioAnoAnterior, { months: cont }).getMonth();
          const ano = add(inicioAnoAnterior, {
            months: cont,
          }).getFullYear();

          if (contMes === 3 && trimestreCont === 1) {
            const valor = this.resultTrimestre.filter(
              t => t.trimestre === trimestreCont,
            )[0].valorCurr;

            if (valor > 0) {
              this.resultTrimestre
                .filter(t => t.trimestre === trimestreCont)
                .map(
                  t =>
                    (t.percent = (
                      (valor * 100) / valorTrimAnterior -
                      100
                    ).toFixed(2)),
                );
            }
            contMes = 0;
            valorTrimAnterior = 0;
            trimestreCont++;
          }

          if (contMes === 3 && trimestreCont === 2) {
            const valor = this.resultTrimestre.filter(
              t => t.trimestre === trimestreCont,
            )[0].valorCurr;

            if (valor > 0) {
              this.resultTrimestre
                .filter(t => t.trimestre === trimestreCont)
                .map(
                  t =>
                    (t.percent = (
                      (valor * 100) / valorTrimAnterior -
                      100
                    ).toFixed(2)),
                );
            }
            contMes = 0;
            valorTrimAnterior = 0;
            trimestreCont++;
          }

          if (contMes === 3 && trimestreCont === 3) {
            const valor = this.resultTrimestre.filter(
              t => t.trimestre === trimestreCont,
            )[0].valorCurr;

            if (valor > 0) {
              this.resultTrimestre
                .filter(t => t.trimestre === trimestreCont)
                .map(
                  t =>
                    (t.percent = (
                      (valor * 100) / valorTrimAnterior -
                      100
                    ).toFixed(2)),
                );
            }
            contMes = 0;
            valorTrimAnterior = 0;
            trimestreCont++;
          }

          if (contMes === 3 && trimestreCont === 4) {
            const valor = this.resultTrimestre.filter(
              t => t.trimestre === trimestreCont,
            )[0].valorCurr;

            if (valor > 0) {
              this.resultTrimestre
                .filter(t => t.trimestre === trimestreCont)
                .map(
                  t =>
                    (t.percent = (
                      (valor * 100) / valorTrimAnterior -
                      100
                    ).toFixed(2)),
                );
            }
            contMes = 0;
            valorTrimAnterior = 0;
            trimestreCont++;
          }

          if (cont <= 11) {
            const valor = this.metas.filter(
              m => m.trimestre === trimestreCont,
            )[0].metaCurr;

            if (valor > 0) {
              this.metas.filter(m => m.trimestre === trimestreCont)[
                contMes
              ].percent = Number(
                (
                  (valor * 100) /
                    this.tabFaturamento.find(e => Number(e.valor) === mes)[
                      `C-${ano}`
                    ][0] -
                  100
                ).toFixed(2),
              );

              valorTrimAnterior += this.tabFaturamento.find(
                e => Number(e.valor) === mes,
              )[`C-${ano}`][0];
            }
          }

          contMes++;
        }

        const totalArea = this.resultTrimestre.reduce(
          (total, item) => total + item.valorCurr,
          0,
        );

        this.resultadosAreas.push({
          resultTrimestre: this.resultTrimestre,
          area,
          totalArea,
        });
      }

      this.resultadosAreas.map((e, i) =>
        e.resultTrimestre.map((t, index) => {
          if (t.trimestre > 1) {
            let percentTrimestre =
              (t.valorCurr * 100) /
              this.resultadosAreas[i].resultTrimestre[index - 1].valorCurr;

            percentTrimestre = percentTrimestre - 100;

            this.resultadosAreas[i].resultTrimestre[
              index
            ].percentTrimestre = percentTrimestre.toFixed(2);
          }
        }),
      );
    },
  },
  beforeMount() {
    this.buscaIndice();
  },
};
</script>
<style>
.v-data-table.tbmetasOpimed tbody tr td {
  font-size: 0.8rem !important;
  height: 25px !important;
  min-width: 130px;
}
.v-input.inputCalcEspelho {
  background-color: grey !important;
}
.v-input.inputCalcEspelho .v-label {
  margin: 5px;
  color: white;
}
.v-input.inputCalcEspelho input {
  color: white;
}
</style>
